// Migrated
<template>
  <div
    class="crown-container flex flex-col justify-center relative"
    :class="`text-${color} ${getSize}`"
  >
    <i-crown
      class="absolute"
      filled
      :font-controlled="false"
      width="100%"
      :class="{ 'crown-image-shadow': shadow }"
    />
    <div
      class="relative flex justify-center flex-col"
      :class="[{ 'crown-text-shadow': shadow }, size === 'sm' ? 'p-[30px]' : 'p-[40px]']"
    >
      <span
        class="text-center block"
        :data-i18n="smallTitleKey"
      >{{ $t(smallTitleKey) }}</span>
      <h4
        class="uppercase font-semibold text-center -mt-1 whitespace-pre-line"
        :data-i18n="mainTitleKey"
      >
        {{ toUnixNewlines($t(mainTitleKey)) }}
      </h4>
    </div>
  </div>
</template>

<script>
export default defineNuxtComponent({
  props: {
    smallTitleKey: {
      type: String,
      default: 'crownSmallTitle',
    },

    mainTitleKey: {
      type: String,
      default: 'crownMainTitle',
    },

    size: {
      type: String,
      default: 'sm',
    },

    shadow: {
      type: Boolean,
      default: true,
    },

    color: {
      type: String,
      default: 'white',
    },
  },

  computed: {
    getSize () {
      switch (this.size) {
        case 'lg':
          return 'crown-lg'
        default:
          return 'crown-sm'
      }
    },
  },
})
</script>

<style lang="scss" scoped>
$base-font-size-sm: .8rem;
$base-font-size-lg: 1rem;

.crown {
  &-sm {
    span {
      font-size: $base-font-size-sm;
    }
    h4 {
      font-size: $base-font-size-sm * 1.37;
    }

    @media (max-width: theme('screens.sm')) {
      $modifier: .9;

      span {
        font-size: $base-font-size-sm * $modifier;
      }

      h4 {
        font-size: $base-font-size-sm * $modifier * 1.37;
      }
    }
  }

  &-lg {
    span {
      font-size: $base-font-size-lg;
    }
    h4 {
      font-size: $base-font-size-lg * 1.37;
    }
  }

  &-image-shadow {
    filter: drop-shadow(2px 2px 3px rgba(0,0,0,.35))
  }

  &-text-shadow {
    * {
      filter: drop-shadow(2px 2px 2px rgba(0,0,0,.4));
    }
  }
}
</style>
